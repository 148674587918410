<template>
	<w-section fill-height :title="$t('customers.wizard.catalog_tree_structure', { step: step })">
        <v-layout column fill-height>
			<v-flex grow>
				<v-form v-model="isValid">
					<w-select v-model="customer.catalog_tree_structure_id" :items="catalogTreeStructures" item-text="name" item-value="id" :loading="loading" required />
				</v-form>
			</v-flex>
			<v-flex shrink text-xs-right>
				<w-btn :disabled="!isValid" @click="next()">{{ $t('next') }}</w-btn>
			</v-flex>
		</v-layout>
	</w-section>
</template>

<script>
import Vue from 'vue'

export default {
	name: 'CustomerCreationDocuments',
    inject: {
        holdingContext: {
            default: () => ({}),
            from: 'holdingContext'
        },
        service: {
            from: 'service'
        }
    },
	props: {
		catalogTreeStructures: {
			required: true,
			type: Array
		},
		step: {
			required: true,
			type: Number
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			loading: false,
			isValid: false
		}
	},
	computed: {
		customer: {
			get: function () {
				return this.value
			},
			set: function (customer) {
				this.$emit('input', customer)
			}
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		}
	},
	watch: {
		catalogTreeStructures: {
			handler: function (catalogTreeStructures) {
				if (catalogTreeStructures.length >= 1) {
					this.setDefaultCatalogTreeStructure()
				}
			}
		}
	},
	methods: {
		next: function () {
			this.$emit('next')
		},
		setDefaultCatalogTreeStructure: function () {
			this.loading = true
			let promise
			if (this.holdingId) {
				promise = this.service.listCatalogTreeStructures(this.holdingId, this.customer.accounting_firm_id, { is_default: true})
			} else {
				promise = this.service.listCatalogTreeStructures(this.customer.accounting_firm_id, { is_default: true})
			}
			return promise.then(catalogTreeStructure => {
					Vue.set(this.customer, 'catalog_tree_structure_id', catalogTreeStructure.id)
				})
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>
