<template>
	<w-section fill-height :title="$t('customers.wizard.apps', { step: step })">
        <v-layout column fill-height>
			<v-flex grow>
				<v-flex mb-3>
					<w-text-info :text="$t('customers.info_apps')" />
				</v-flex>
				<VendorApplicationsExpansionPanel
					:loading="loading"
					:value="apps"
					@select="selectRow"
					@unselect="unselectRow"
				/>
			</v-flex>
			<v-flex xs12>
				<w-checkbox v-model="applyAppsToUser" :label="$t('customers.labels.apply_apps_to_user')" hide-details :disabled="!hasHomeApps()" />
			</v-flex>
			<v-flex shrink text-xs-right>
				<w-btn @click="next">{{ $t('next') }}</w-btn>
			</v-flex>
		</v-layout>
	</w-section>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
	name: 'CustomerCreationApps',
	components: {
		VendorApplicationsExpansionPanel: () => ({
			component: import('@/components/Applications/VendorApplicationsExpansionPanel')
		})
	},
	mixins: [ApplicationsModuleGuard],
    inject: {
        holdingContext: {
            default: () => ({}),
            from: 'holdingContext'
        },
        customerService: {
            from: 'service'
        }
    },
	props: {
		step: {
			required: true,
			type: Number
		},
		value: {
			required: true,
			type: Object
		},
		isActive: {
			required: true,
			type: Boolean
		}
	},
	data: function () {
		return {
			selectedApps: [],
			loading: false,
			apps: [],
			applyAppsToUser: false
		}
	},
	computed: {
		customer: {
			get: function () {
				return this.value
			},
			set: function (customer) {
				this.$emit('input', customer)
			}
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		}
	},
	watch: {
		isActive: {
			handler: function(newValue) {
				if (newValue) {
					this.loadApps()
				}
			}
		}
	},
	methods: {
		parseApp: function (app) {
			return {
				title: app.title,
				loading: false,
				params_vendor: app.params_vendor ?? [],
				params: {},
				selected: false,
				id: app.id,
				catalog_application_id: app.catalog_application_id,
				is_home: app.is_home
			}
		},
		next: function () {
			if (this.customer) {
				this.customer.apps = [...this.selectedApps]
				this.customer.apply_apps_to_user = this.applyAppsToUser
				this.$emit('next')
			}
		},
		reset: function () {
			this.selectedApps = []
		},
		loadApps: function () {
			this.loading = true
			this.apps = []
			let promise
			if (this.holdingId) {
				promise = this.customerService.listSubscriptions(this.holdingId, this.customer?.accounting_firm_id, {'with_vendor_params': true})
			} else {
				promise = this.service.listSubscriptions(this.accountingFirmId, {'with_vendor_params': true})
			}

			promise
				.then(apps => {
					this.apps = apps.map(this.parseApp)
					this.apps.forEach(async app => {
						app.formattedParams = await this.service.listApplicationVendorParameters(this.accountingFirmId, app.id);
					})
					this.selectedApps.forEach(selectedApp => {
						const appToSelect = this.apps.find(app => app.catalog_application_id === selectedApp.catalog_application_id)
						appToSelect.selected = true
					})
				})
				.finally(() => {
					this.loading = false
				})
		},
		selectRow: function (row, params = {}) {
			this.selectedApps.push({'parent_subscription_id': row.id, 'catalog_application_id': row.catalog_application_id, 'params': params, 'is_home': row.is_home})
			row.selected = true
			row.params  = params
		},
		unselectRow: function (row) {
			this.selectedApps = this.selectedApps.filter(app => app.parent_subscription_id != row.id)
			row.selected = false
		},
		hasHomeApps: function (){
			return this.selectedApps.filter(app => app.is_home).length > 0
		}
	}
}
</script>
