<template>
	<w-section fill-height :title="$t('customers.wizard.accounting_periods', { step: step })">
        <v-layout column fill-height>
			<v-flex grow>
				<AccountingPeriodForm ref="form" v-model="accountingPeriod" :is-valid.sync="isValid" :with-name="false" />
			</v-flex>
			<v-flex shrink text-xs-right>
				<w-btn :disabled="!isValid" @click="next()">{{ $t('next') }}</w-btn>
			</v-flex>
		</v-layout>
	</w-section>
</template>

<script>
export default {
	name: 'CustomerCreationAccountingPeriod',
	components: {
		AccountingPeriodForm: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/AccountingPeriods/AccountingPeriodForm')
		})
	},
	props: {
		step: {
			required: true,
			type: Number
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			accountingPeriod: {
				end: null,
				start: null
			},
			isValid: false
		}
	},
	computed: {
		customer: {
			get: function () {
				return this.value
			},
			set: function (customer) {
				this.$emit('input', customer)
			}
		}
	},
	methods: {
		next: function () {
			this.customer.accounting_period_start = this.accountingPeriod.start
			this.customer.accounting_period_end = this.accountingPeriod.end
			this.$emit('next')
		},
		reset: function () {
			this.$refs.form.reset()
		}
	}
}
</script>
