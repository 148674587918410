<template>
	<w-section fill-height :title="$t('customers.wizard.basic', { step: step })">
		<v-layout column fill-height>
			<v-flex grow>
				<v-form ref="form" v-model="isValid">
					<v-layout row wrap>
						<v-flex v-if="isInHoldingMode" pr-1 :pt-1="!customer.accounting_firm_id" xs12 sm6>
							<w-select
								v-model="customer.accounting_firm_id"
								:items="workspaces"
								item-text="name"
								item-value="id"
								:label="$t('customers.labels.workspace')"
								:loading="workspacesLoading"
								required
							/>
						</v-flex>
						<v-flex pl-1 :sm12="!isInHoldingMode" :sm6="isInHoldingMode" xs12>
							<WSiretInput v-model="customer.siret" :label="$t('customers.labels.optional_siret')" />
						</v-flex>
					</v-layout>
				</v-form>
			</v-flex>
			<v-flex text-xs-right shrink>
				<w-btn :disabled="!isValid" :loading="loading" @click="next()">{{ $t('next') }}</w-btn>
			</v-flex>
		</v-layout>
	</w-section>
</template>

<script>
import { Bus, Events } from '@/events/AppEvents'
import AppService from '@/services/AppService'

export default {
	name: 'CustomerCreationBasic',
	components: {
		WSiretInput: () => ({
			component: import('@/components/Commons/Inputs/WSiretInput')
		})
	},
	inject: {
		holdingContext: {
			default: () => ({}),
			from: 'holdingContext'
		},
		service: {
			from: 'service'
		}
	},
	props: {
		step: {
			required: true,
			type: Number
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			isValid: false,
			loading: false,
			workspaces: [],
			workspacesLoading: false
		}
	},
	computed: {
		customer: {
			get: function () {
				return this.value
			},
			set: function (customer) {
				if (customer.siret === '') {
					delete customer.siret
				}
				this.$emit('input', customer)
			}
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		isInHoldingMode: function () {
			return !!this.holdingId
		}
	},
	watch: {
		holdingId: {
			handler: function (holdingId) {
				if (holdingId) {
					this.listWorkspaces()
				}
			}
		}
	},
	mounted: function () {
		if (this.isInHoldingMode) {
			this.listWorkspaces()
		}
	},
	methods: {
		findCustomerDataFromSiret: function () {
			if (this.isInHoldingMode) {
				return this.service.findCustomerData(this.holdingId, this.customer.accounting_firm_id, this.customer.siret)
			} else {
				return this.service.getSiretData(this.customer.accounting_firm_id, this.customer.siret)
			}
		},
		listWorkspaces: function () {
			this.workspacesLoading = true

			return this.service
				.listAccountingFirms(this.holdingId)
				.then(workspaces => {
					workspaces.sort(AppService.compareValues('name', 'asc'))
					this.workspaces = workspaces
					if (this.workspaces.length == 1) {
						this.customer.accounting_firm_id = this.workspaces[0].id
					}
				})
				.finally(() => {
					this.workspacesLoading = false
				})
		},
		next: function () {
			if (this.customer.siret) {
				this.loading = true
				this.findCustomerDataFromSiret()
					.then(customerData => {
						Object.entries(customerData).forEach(([key, value]) => {
							this.customer[key] = value
						})
						this.customer.exists = true
						this.$emit('next')
					})
					.catch(error => {
						if (error?.name === 'NoDataFoundFromSiretException') {
							Bus.emit(Events.SNACKBAR_WARNING, this.$t('clients.no_data_found_from_siret'))
							this.customer.exists = false
							this.$emit('next')
						} else if (error?.name === 'SiretAlreadyUsedByYouException') {
							Bus.emit(Events.SNACKBAR_WARNING, this.$t('clients.siret_already_used_by_you'))
						} else if (error?.name === 'SiretAlreadyUsedByAnotherException') {
							Bus.emit(Events.SNACKBAR_WARNING, this.$t('clients.siret_already_used_by_another'))
						} else {
							throw error
						}
					})
					.finally(() => {
						this.loading = false
					})
			} else {
				this.customer.exists = false
				this.$emit('next')
			}
		},
		reset: function () {
			this.$refs.form.reset()
		}
	}
}
</script>