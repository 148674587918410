<template>
	<w-section fill-height :title="$t('customers.wizard.general', { step: step })">
        <v-layout column fill-height>
			<v-flex grow>
				<v-form ref="form" v-model="isValid">
					<v-layout row wrap>
						<v-flex :pr-1="$vuetify.breakpoint.mdAndUp" xs12 md8 lg9>
							<w-text-input v-model="customer.company" :label="$t('customers.labels.company')" required :rules="[rules.noSpecialChar]" />
						</v-flex>
						<v-flex :pl-1="$vuetify.breakpoint.mdAndUp" xs12 md4 lg3>
								<w-text-input
									v-model="customer.client_code"
									:label="$t('customer.general.client_code')"
									maxlength="191"
									:rules="[rules.noSpecialChar]"
									validate-on-blur
								/>
						</v-flex>
						<template v-if="customer.exists">
							<v-flex pr-1 sm6 xs12>
								<w-text-input v-model="customer.siren" :label="$t('customers.labels.siren')" mask="### ### ###" :rules="[rules.siren]" />
							</v-flex>
							<v-flex p-l-1 sm6 xs12>
								<WSiretInput v-model="customer.siret" :label="$t('customers.labels.siret')" />
							</v-flex>
						</template>
						<v-flex pr-1 sm6 xs12>
							<w-date-picker v-model="customer.creation_date" :label="$t('customers.labels.creation_date')" />
						</v-flex>
						<v-flex pl-1 sm6 xs12>
							<v-autocomplete v-model="customer.naf" :items="nafCodes" item-text="naf_code" item-value="id" :label="$t('customers.labels.naf_code')" :loading="nafCodesLoading" />
						</v-flex>
						<v-flex xs12>
							<v-text-field v-model="customer.society_type" :label="$t('customers.labels.society_type')" />
						</v-flex>
					</v-layout>
				</v-form>
			</v-flex>
			<v-flex shrink text-xs-right>
				<w-btn :disabled="!isValid" @click="next()">{{ $t('next') }}</w-btn>
			</v-flex>
		</v-layout>
	</w-section>
</template>

<script>
import Validator from '@/mixins/Validator'

export default {
	name: 'CustomerCreationGeneral',
	components: {
        WSiretInput: () => ({
            component: import('@/components/Commons/Inputs/WSiretInput')
        })
	},
	mixins: [Validator],
    inject: {
        service: {
            from: 'service'
        }
    },
	props: {
		step: {
			required: true,
			type: Number
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			isValid: false,
			nafCodes: [],
			nafCodesLoading: false
		}
	},
	computed: {
		customer: {
			get: function () {
				return this.value
			},
			set: function (customer) {
				this.$emit('input', customer)
			}
		}
	},
	mounted: function () {
		this.loadNafCodes()
	},
	methods: {
		loadNafCodes: function () {
			this.nafCodesLoading = true
			return this.service.listNafCodes()
				.then(nafCodes => {
					this.nafCodes = nafCodes
				})
				.finally(() => {
					this.nafCodesLoading = false
				})
		},
		next: function () {
			this.$emit('next')
		},
		reset: function () {
			this.$refs.form.resetValidation()
		}
	}
}
</script>
