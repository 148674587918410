<template>
	<w-section fill-height :title="$t('customers.wizard.accountants', { step: step })">
        <v-layout column fill-height>
			<v-flex grow>
				<w-text-info :text="$t('customers.theme_management')" />
				<v-data-table
					v-model="selected"
					class="elevation-1"
					:headers="headers"
					:items="accountants"
					:loading="accountantsLoading"
					:pagination.sync="pagination"
					select-all
				>
					<template v-slot:headers="props">
						<tr>
							<th
								v-for="header in props.headers"
								:key="header.text"
								:class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
								@click="changeSort(header.value)"
							>
								<v-icon small>arrow_upward</v-icon>
								{{ header.text }}
							</th>
							<th style="width:100px">
								<v-checkbox
									color="primary"
									hide-details
									:indeterminate="props.indeterminate"
									:input-value="props.all"
									@click.stop="toggleAll()" 
								/>
							</th>
						</tr>
					</template>
					<template v-slot:items="props">
						<tr @click="props.selected = !props.selected">
							<td>{{ props.item.username }}</td>
							<td>
								<v-checkbox
									color="primary"
									hide-details
									:input-value="props.selected"
								/>
							</td>	
						</tr>
					</template>
				</v-data-table>
			</v-flex>
			<v-flex shrink text-xs-right>
				<w-btn-save :loading="loading" @click="createCustomer" />
			</v-flex>
		</v-layout>
	</w-section>
</template>

<script>
import { mapState } from 'vuex'

import { Bus, Events } from '@/events/AppEvents'

export default {
	name: 'CustomerCreationAccountants',
    inject: {
        holdingContext: {
            default: () => ({}),
            from: 'holdingContext'
        },
        service: {
            from: 'service'
        }
    },
	props: {
		step: {
			required: true,
			type: Number
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			accountants: [],
			accountantsLoading: false,
			checkAll: false,
			checkboxes: [],
			headers: [
				{
                    text: this.$t('customers.labels.accountant'),
					value: 'username'
				}
			],
			loading: false,
			pagination: {
      			sortBy: 'username'
    		},
			selected: [],
			roles: []
		}
	},
	computed: {
		...mapState({
			user: state => state.user.data
		}),
		customer: {
			get: function () {
				return this.value
			},
			set: function (customer) {
				this.$emit('input', customer)
			}
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		}
	},
	watch: {
		'customer.accounting_firm_id': {
			handler: function (accountingFirmId) {
				if (accountingFirmId) {
					this.loadAccountants()
					this.getRoles(accountingFirmId)
				}
			}
		}
	},
	mounted: function () {
		if (this.customer.accounting_firm_id) {
			this.loadAccountants()
			this.getRoles(this.customer.accounting_firm_id)
		}
		
	},
	methods: {
		changeSort: function (column) {
			if (this.pagination.sortBy === column) {
				this.pagination.descending = !this.pagination.descending
			} else {
				this.pagination.sortBy = column
				this.pagination.descending = false
			}
		},
		createCustomer: function () {
			this.loading = true
			this.customer.accountants = this.selected.map(({id}) => id)

			const customerData = {...this.customer}
			if (customerData.siret === '') {
				delete customerData.siret
			}

			let promise
			if (this.holdingId) {
				promise = this.service.createCustomer(this.holdingId, this.customer.accounting_firm_id, customerData)
			} else {
				promise = this.service.createCustomer(this.customer.accounting_firm_id, customerData)
			}

			return promise.then(customer => {
					Bus.emit(Events.SNACKBAR_SUCCESS, this.$t('customers.messages.company_created', { name: customer.company }))
					this.$emit('save', customer)
				})
				.finally(() => {
					this.loading = false
				})
		},
		loadAccountants: function () {
			this.accountantsLoading = true
			let promise
			if (this.holdingId) {
				promise = this.service.listAccountants(this.holdingId, this.customer.accounting_firm_id)
			} else {
				promise = this.service.listAccountants(this.customer.accounting_firm_id)
			}
			
			return promise.then(accountants => {
					return accountants.map(accountant => {
						const result = {
							id: accountant.id,
							role_id: accountant.role_id.role_id
						}

						if (accountant.firstname && accountant.lastname) {
							result.username = `${accountant.firstname} ${accountant.lastname}`
						} else {
							result.username = accountant.email
						}

						return result
					})
				})
				.then(accountants => {
					this.accountants = accountants
					this.selected.splice(0, this.selected.length)
					const adminRoleId = this.roles.find(role => role.key === "admin")?.id
					accountants.forEach(accountant => {
						if (accountant.role_id == adminRoleId || accountant.id == this.user.id) {
							this.selected.push(accountant)
						}
					})
				})
				.finally(() => {
					this.accountantsLoading = false
				})
		},
		toggleAll: function () {
			if (this.selected.length == 0) {
				this.selected = this.accountants.slice()
			} else {
				this.selected = []
			}
    	},
		getRoles: function (accountingFirmId) {
			this.service.getRoles(accountingFirmId)
				.then(res => {
					this.roles = res
				})
		}
	}
}
</script>
