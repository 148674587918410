<template>
    <v-layout column fill-height>
		<v-stepper v-model="step" class="fill-height">
			<v-stepper-header>
				<template v-for="(section, index) in numberOfDisplayedSections">
					<v-stepper-step :key="`${index}-step`" :class="{'pointer': maxStep > index }" :complete="step > index + 1" :step="index + 1" @click="goToSection(index + 1)"></v-stepper-step>
					<v-divider v-if="index < numberOfDisplayedSections" :key="`${index}-divider`"></v-divider>
				</template>
			</v-stepper-header>
			<v-stepper-content v-for="(section, index) in sections" :key="index" class="ma-0 pa-0" :step="index + 1" style="height:calc(100% - 72px)">
				<v-card style="height:100%">
					<component
						:is="section.component"
						ref="forms"
						v-model="customer"
						:catalog-tree-structures="catalogTreeStructures"
						:has-ecm-module="hasDocumentModule"
						:step="step"
						:is-active="step == index + 1"
						@next="goToNextSection()"
						@save="onCustomerCreated($event)"
					/>
				</v-card>
			</v-stepper-content>
		</v-stepper>
	</v-layout>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import CustomerCreationAccountingPeriod from '@/components/CustomersManager/CustomerCreation/CustomerCreationAccountingPeriod'
import CustomerCreationAccountants from '@/components/CustomersManager/CustomerCreation/CustomerCreationAccountants'
import CustomerCreationBasic from '@/components/CustomersManager/CustomerCreation/CustomerCreationBasic'
import CustomerCreationDocuments from '@/components/CustomersManager/CustomerCreation/CustomerCreationDocuments'
import CustomerCreationGeneral from '@/components/CustomersManager/CustomerCreation/CustomerCreationGeneral'
import CustomerCreationUser from '@/components/CustomersManager/CustomerCreation/CustomerCreationUser'
import CustomerCreationApps from '@/components/CustomersManager/CustomerCreation/CustomerCreationApps.vue'

export default {
    name: 'CustomerCreationWizard',
    inject: {
        holdingContext: {
            default: () => ({}),
            from: 'holdingContext'
        },
		service: {
			from: 'service'
		}
    },
	props: {
		accountingFirmId: {
			default: null,
			required: false,
			type: Number
		}
	},
    data: function () {
        return {
			catalogTreeStructures: [],
			catalogTreeStructuresLoaded: false,
            customer: {},
			hasDocumentModuleInHoldingContext: false,
			hasApplicationsModuleInHoldingContext :false,
			maxStep: 1,
			step: 1
        }
    },
    computed: {
		...mapState({
			modules: state => state.modules.list
		}),
		hasDocumentModule: function () {
			if (this.holdingId) {
				return this.hasDocumentModuleInHoldingContext
			} else {
				return this.modules.filter(module => module.name == 'documents' && module.is_active).length === 1
			}
		},
		hasApplicationsModule: function () {
			if (this.holdingId) {
				return this.hasApplicationsModuleInHoldingContext
			} else {
				return this.modules.filter(module => module.name == 'applications' && module.is_active).length === 1
			}
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		numberOfDisplayedSections: function () {
			return this.sections.filter(section => !section.hidden).length
		},
        sections: function () {
            let result = [
                { component: CustomerCreationBasic, key: 'basic' },
				{ component: CustomerCreationGeneral, key: 'general' },
				{ component: CustomerCreationAccountingPeriod, key: 'accounting-period' }
            ]

			if (this.hasDocumentModule && (!this.catalogTreeStructuresLoaded || this.catalogTreeStructures.length > 1)) {
				result.push({ component: CustomerCreationDocuments, key: 'catalog-tree-structures' })
			}

			result = result.concat([
				{ component: CustomerCreationUser, key: 'administrator' },
			])

			if (this.hasApplicationsModule) {
				result.push({ component: CustomerCreationApps, key: 'apps' })
			}

			result = result.concat([
				{ component: CustomerCreationAccountants, key: 'accountants' }
			])

			if (this.hasDocumentModule && this.catalogTreeStructures.length <= 1) {
				result.push({ component: CustomerCreationDocuments, hidden: true, key: 'catalog-tree-structures' })
			}

			return result
        }
    },
	watch: {
		'accountingFirmId': {
			handler: function (accountingFirmId) {
				this.customer.accounting_firm_id = accountingFirmId
			}
		},
		'customer.accounting_firm_id': {
			handler: function (accountingFirmId) {
				if (accountingFirmId) {
					this.loadCatalogTreeStructures()
				} else {
					this.catalogTreeStructures.splice(0, this.catalogTreeStructures.length)
					this.catalogTreeStructuresLoaded = false
				}
			}
		},
		holdingId: {
			handler: function (holdingId) {
				if (holdingId) {
					this.checkHasEcmModule()
					this.checkHasApplicationsModule()
				} else {
					this.hasDocumentModuleInHoldingContext = false
					this.hasApplicationsModuleInHoldingContext = false
				}
			}
		}
	},
	mounted: function () {
		if (this.accountingFirmId) {
			this.customer.accounting_firm_id = this.accountingFirmId
		}
		if (this.holdingId) {
			this.checkHasEcmModule()
			this.checkHasApplicationsModule()
		}
	},
    methods: {
		checkHasEcmModule: function () {
			this.service.findModule(this.holdingId, 'documents')
				.then(() => {
					this.hasDocumentModuleInHoldingContext = true
				})
				.catch(() => {
					this.hasDocumentModuleInHoldingContext = false
				})
		},
		checkHasApplicationsModule: function () {
			this.service.findModule(this.holdingId, 'applications')
				.then(() => {
					this.hasApplicationsModuleInHoldingContext = true
				})
				.catch(() => {
					this.hasApplicationsModuleInHoldingContext = false
				})
		},
		goToNextSection: function () {
			this.step += 1
			this.maxStep = this.step
		},
		goToSection: function (sectionIndex) {
			if (this.maxStep <= sectionIndex) {
				return
			}

			this.maxStep = sectionIndex
			this.step = sectionIndex
			if (sectionIndex == 1) {
				this.reset()
			}
		},
		loadCatalogTreeStructures: function () {
			let promise
			if (this.holdingId) {
				promise = this.service.listCatalogTreeStructures(this.holdingId, this.customer.accounting_firm_id)
			} else {
				promise = this.service.listCatalogTreeStructures(this.customer.accounting_firm_id)
			}

			return promise.then(catalogTreeStructures => {
					this.catalogTreeStructures = catalogTreeStructures
					if (this.catalogTreeStructures.length == 1) {
						Vue.set(this.customer, 'catalog_tree_structure_id', this.catalogTreeStructures[0].id)
					}
				})
				.finally(() => {
					this.catalogTreeStructuresLoaded = true
				})
		},
		onCustomerCreated: function (vendor) {
			this.$emit('customer-created', vendor)
		},
		reset: function () {
			for (const key in this.customer) {
				if (this.holdingId || (key != 'accounting_firm_id' && key != 'catalog_tree_structure_id')) {
					delete this.customer[key]
				}
			}
			this.$refs.forms.forEach(component => {
				component.reset?.()
			})
			this.step = 1
		}
	}
}
</script>

<style scoped>
:deep(.v-stepper__wrapper) {
	height: 100% !important;
}
</style>